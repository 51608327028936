import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardJakubowObelisk = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/jakubow/obelisk/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/jakubow/obelisk'}
      title={'Jakubów obelisk'}
    >
      Obelisk upamiętniający bohaterów poległych w walkach o niepodległość
      Ojczyzny w latach 1919-1920 został odsłonięty w 1922 roku, a więc w
      czwartą rocznicę odzyskania Niepodległości. W okresie PRL na tym pomniku
      umieszczona była tablica poświęcona bohaterom poległym za wolność Ojczyzny
      w latach 1918-1945. Pomnikiem opiekuje się Szkoła im. Orła Białego w
      Jakubowie.
    </MonumentCard>
  )
}

export default MonumentCardJakubowObelisk
