import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardZ80LecieWS = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/jakubow/80leciews/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/jakubow/80-lecie-wybuchu-ii-wojny-swiatowej'}
      title={'Jakubów, 80 lecie wybuchu II Wojny Światowej'}
    >
      Po prawej stronie drogi na Mińsk Mazowiecki mieści się pomnik 80 lecia
      wybuchu II Wojny Światowej. Pielęgnuje on pamięć o ofiarach mieszkańcach
      powiatu mińskiego, którzy zginęli z rąk najeźdźców z III Rzeszy
      Niemieckiej i Związku Sowieckiego. Erygowany 1 września 2019 roku z
      inicjatywy Muzeum Ziemi Mińskiej.
    </MonumentCard>
  )
}

export default MonumentCardZ80LecieWS
