import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardZ80LecieWS from '../../../monuments/jakubow/MonumentCard80lecieWS'
import MonumentCardJakubowObelisk from '../../../monuments/jakubow/MonumentCardJakubowObelisk'
import MonumentCardRocznik from '../../../components/MonumentCard/MonumentCardRocznik'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const JakubowArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Jakubów" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Jakubów</Header1>
        <MonumentCardZ80LecieWS />
        <MonumentCardJakubowObelisk />
        <MonumentCardRocznik
          title={'Opowieść o pamiętnym polskim Wrześniu z mariawitami w tle'}
          author={'Krzysztof Mazur'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/JakubowRoczniktom262018KrzysztofMazurOpowiescopamietnympolskimWrzesniuzmariawitamiwtle%E2%80%A6.pdf'
          }
        />
        <MonumentCardAsset
          title={
            'Dla pamięci. Moje wspomnienia ze wsi Nart, Rocznik Mińskomazowiecki tom 21, 2013 r.'
          }
          author={'Eliasz Roman Wąsak'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Jakub%C3%B3w+Eliasz+Roman+W%C4%85sak+Dla+pami%C4%99ci+moje+wspomnienia+ze+wsi+Nart.mp3'
          }
        />
        <MonumentCardAsset
          title={'Mistów - Dzieje wsi i nazwy (zarys)'}
          author={'Sylwester Wocial'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Sylwester+Wocial+Misto%CC%81w+-+Dzieje+wsi+i+nazwy+(zarys).mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default JakubowArchive
